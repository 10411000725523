@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Noto300';
    src: url('./assets/fonts/Poppins-Light.ttf') format('truetype'),
        url('./assets/fonts/Poppins-Light.woff2') format('woff2'),
        url('./assets/fonts/Poppins-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Noto400';
    src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype'),
        url('./assets/fonts/Poppins-Medium.woff2') format('woff2'),
        url('./assets/fonts/Poppins-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Noto700';
    src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype'),
        url('./assets/fonts/Poppins-Bold.woff2') format('woff2'),
        url('./assets/fonts/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Noto900';
    src: url('./assets/fonts/Poppins-Black.ttf') format('truetype'),
        url('./assets/fonts/Poppins-Black.woff2') format('woff2'),
        url('./assets/fonts/Poppins-Black.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

html,
.Toastify__toast-body {
    font-family: 'Noto300';
    font-size: 15px;
    color: #58585a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Noto700';
}

p {
    margin-bottom: 0.5em;
}

button:disabled {
    opacity: 0.5;
}

.product-description ul {
    list-style: disc;
    margin: 1em 0 1em 1em;
}

nav a.active {
    color: #e122fc;
}

.MuiLinearProgress-colorSecondary {
    background-color: transparent !important;
}

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
    color: white !important;
}

.Toastify__toast-body>div:last-child {
    color: #d0d0d0;
}

.mainContent {
    min-height: 84.8vh;
    margin-top: 85px;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: black;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #e122fc;
}

.slick-prev:before,
.slick-next:before {
    display: none;
}

.sp-tabs .MuiTab-textColorSecondary {
    font-weight: 800;
}

.sp-tabs .MuiTab-textColorSecondary:not(.Mui-selected) {
    color: #828282;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    background: #1a1a1a;
    color: #fff;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    fill: #fff;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
    border-bottom: solid 1px #333;
}

.sp-table-body tr:not(:last-child) {
    border-bottom: solid 1px #262626;
}

.wave-item:not(:last-child) {
    border-bottom: solid 1px #363636;
}

.has-readmore:before {
    content: "";
    width: 100%;
    height: 50px;
    background: linear-gradient(360deg, black, transparent);
    display: block;
    position: absolute;
    bottom: 0;
}

.has-readmore {
    max-height: 107px;
    position: relative;
    transition: max-height 0.5s;
    overflow-y: hidden;
}

body {
    background: #000;
}

.filter-section {
    max-height: calc(100vh - 190px);
}

@media only screen and (max-width: 600px) {
    .filter-section {
        max-height: 20vh;
    }
}

.glow-heading {
    animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
    0% {
        text-shadow: 0 0 20px #2d9da9;
    }

    100% {
        text-shadow: 0 0 30px #34b3c1, 0 0 10px #4dbbc7;
    }
}

.home-scroll {
    -webkit-animation: sdb05 1.5s infinite;
    animation: sdb05 1.5s infinite;
}


@keyframes sdb05 {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translate(0px, 20px);
        opacity: 0;
    }
}